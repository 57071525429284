import { computed, defineComponent } from '@vue/composition-api';
import { injectStrict } from '@/lib/misc';
import { StoreKey } from '@/symbols';
export default defineComponent({
    props: {
        value: {
            type: String,
            default: null
        }
    },
    setup(props, { emit }) {
        const store = injectStrict(StoreKey);
        const selectedLocation = computed({
            get: () => props.value,
            set: value => emit('input', value)
        });
        const locations = computed(() => store.getters['locations/locations']);
        return {
            selectedLocation,
            locations
        };
    }
});
