import { ref, watch, reactive, defineComponent, computed } from '@vue/composition-api';
import GeolocationAlert from '@/components/GeolocationAlert.vue';
import ReportAreaField from '@/components/ReportAreaField.vue';
import { dispatchAxiosErrorAlert, injectStrict } from '@/lib/misc';
import { AxiosKey, StoreKey } from '@/symbols';
import MapField from '@/components/MapField.vue';
import { useToast } from 'vue-toastification/composition';
import CameraGallery from '@/components/CameraGallery.vue';
import router from '@/router';
import PreviousReportAreas from '@/components/PreviousReportAreas.vue';
import SearchAddressControl from '@/components/SearchAddressControl.vue';
import ErrorLabel from '@/components/ErrorLabel.vue';
export default defineComponent({
    components: {
        PreviousReportAreas,
        GeolocationAlert,
        CameraGallery,
        ReportAreaField,
        MapField,
        SearchAddressControl,
        ErrorLabel
    },
    props: {
        institution: {
            type: Object,
            required: true
        },
        geolocation: {
            type: GeolocationPosition
        }
    },
    setup(props) {
        const store = injectStrict(StoreKey);
        const axios = injectStrict(AxiosKey);
        const validatorRef = ref();
        const toast = useToast();
        const area = computed(() => props.institution.geometry);
        const state = reactive({
            latLng: null,
            images: [],
            selectedPreviousLocation: null
        });
        const fields = reactive({
            categoryId: {
                label: 'Tip sesizare',
                value: '',
                items: computed(() => props.institution.categories.map(({ _id, name }) => ({
                    id: _id,
                    label: name
                }))),
                rules: {
                    required: true
                }
            },
            description: {
                label: 'Descriere',
                value: '',
                rules: {
                    required: true
                }
            }
        });
        const submit = () => {
            const data = {
                institutionId: props.institution._id,
                location: {
                    type: 'Point',
                    coordinates: state.latLng ? state.latLng.slice().reverse() : undefined
                },
                categoryId: fields.categoryId.value,
                description: fields.description.value
            };
            return axios.post('my-citizen-reports', data)
                .then(async (res) => {
                const citizenReportId = res.data.data.id;
                toast.success(`Sesizarea cu numarul ${citizenReportId} a fost inregistrat. Va multumim.`);
                if (area.value) {
                    try {
                        await store.dispatch('locations/setLocation', props.institution);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
                return Promise.all(state.images.map(image => {
                    const headers = { 'Content-Type': 'multipart/form-data' };
                    const data = new FormData();
                    data.append('file', image.data);
                    return axios.post(`my-citizen-reports/${res.data.data.id}/photos`, data, { headers });
                }))
                    .then(() => axios.post(`my-citizen-reports/${citizenReportId}/confirm`))
                    .catch((err) => dispatchAxiosErrorAlert(store, err));
            })
                .then(() => {
                return router.push({ name: 'home' });
            })
                .catch((err) => {
                if (err.response?.status === 422) {
                    const { county, locality, ...errors } = err.response?.data?.data || {};
                    if (validatorRef.value) {
                        validatorRef.value.setErrors({
                            ...errors,
                            reportArea: { county, locality }
                        });
                    }
                }
                else {
                    dispatchAxiosErrorAlert(store, err);
                }
            });
        };
        watch(() => props.geolocation, (geolocation) => {
            if (geolocation) {
                state.latLng = [geolocation.coords.latitude, geolocation.coords.longitude];
            }
        }, { immediate: true, deep: true });
        return {
            area,
            validatorRef,
            state,
            fields,
            toast,
            submit
        };
    }
});
