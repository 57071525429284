import { reactive, defineComponent, watch } from '@vue/composition-api';
import GeolocationAlert from '@/components/GeolocationAlert.vue';
// import PreviousReportAreas from '@/components/PreviousReportAreas.vue'
import ReportAreaField from '@/components/ReportAreaField.vue';
import ReportSecond from './Second.vue';
import { dispatchAxiosErrorAlert, injectStrict } from '@/lib/misc';
import { AxiosKey, StoreKey } from '@/symbols';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        GeolocationAlert,
        // PreviousReportAreas,
        ReportAreaField,
        ReportSecond
    },
    setup() {
        const store = injectStrict(StoreKey);
        const axios = injectStrict(AxiosKey);
        const toast = useToast();
        const state = reactive({
            loading: false,
            institution: null,
            geolocationAlert: true,
            geolocation: null
        });
        const setInstitution = (institution) => {
            state.institution = institution;
        };
        const clearInstitution = () => {
            state.institution = null;
        };
        const findInstitution = ({ county, locality }) => {
            state.loading = true;
            state.institution = null;
            axios.get(`areas/find-institution/${county._id}/${locality._id}`)
                .then(res => res.data.data
                ? setInstitution(res.data.data)
                : toast.error('In localitatea selectata nu se pot raporta sesizari.'))
                .catch((err) => dispatchAxiosErrorAlert(store, err))
                .finally(() => {
                state.loading = false;
            });
        };
        watch(() => state.geolocation, geolocation => {
            if (geolocation && !state.institution) {
                axios.get(`areas/coordinates/${geolocation.coords.latitude}/${geolocation.coords.longitude}`)
                    .then(res => {
                    if (!res.data.data) {
                        state.geolocation = null;
                        toast.error('In zona in care va aflati momentan, nu se pot raporta sesizari.');
                    }
                    else {
                        setInstitution(res.data.data);
                    }
                })
                    .catch((err) => dispatchAxiosErrorAlert(store, err));
            }
        }, { immediate: true });
        const changeReportArea = (area) => {
            state.geolocation = null;
            if (area) {
                findInstitution(area);
            }
            else {
                clearInstitution();
            }
        };
        return {
            findInstitution,
            clearInstitution,
            changeReportArea,
            state
        };
    }
});
