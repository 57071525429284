import { computed, defineComponent, reactive } from '@vue/composition-api';
export default defineComponent({
    props: {
        errors: {
            type: [String, Array],
            required: true
        }
    },
    setup(props) {
        const state = reactive({
            errors: computed(() => Array.isArray(props.errors) ? props.errors : [props.errors])
        });
        return {
            state
        };
    }
});
