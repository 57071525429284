import { reactive, defineComponent, onMounted } from '@vue/composition-api';
import { getCurrentPosition, getGeolocationErrorMessage } from '@/lib/location';
export default defineComponent({
    setup(_, { emit }) {
        const state = reactive({
            locating: false,
            retryable: true,
            error: null
        });
        const tryGettingGeolocation = () => {
            state.retryable = true;
            state.error = null;
            state.locating = true;
            getCurrentPosition()
                .then(position => {
                emit('dismiss');
                emit('input', position);
            })
                .catch(err => {
                state.retryable = err.code !== 1;
                state.error = getGeolocationErrorMessage(err.code);
            })
                .finally(() => {
                state.locating = false;
            });
        };
        onMounted(tryGettingGeolocation);
        return {
            state,
            tryGettingGeolocation
        };
    }
});
