import 'ol/ol.css';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { reverse } from 'rambda';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { Point, Polygon } from 'ol/geom';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { baseMapConfig } from '@/config';
import ErrorLabel from '@/components/ErrorLabel.vue';
const extent = new Polygon(baseMapConfig.extent).transform('EPSG:4326', 'EPSG:3857').getExtent();
const source = new VectorSource();
const layer = new VectorLayer({ source });
const geojson = new GeoJSON({
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857'
});
const map = new Map({
    layers: [
        new TileLayer({
            source: new OSM()
        }),
        layer
    ],
    view: new View({
        zoom: baseMapConfig.zoom,
        minZoom: baseMapConfig.minZoom,
        maxZoom: baseMapConfig.maxZoom,
        center: transform(baseMapConfig.center, 'EPSG:4326', 'EPSG:3857'),
        padding: [25, 25, 25, 25],
        extent
    })
});
export default defineComponent({
    components: {
        ErrorLabel
    },
    props: {
        value: {
            type: Array,
            default: () => null
        },
        area: {
            type: Object,
            default: () => null
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const mapRef = ref();
        const state = reactive({
            marker: null,
            area: null,
            areaFitted: false,
            position: computed({
                get: () => props.value,
                set: v => emit('input', v)
            }),
            map
        });
        const fitMap = (extent, options = {}) => {
            state.map.getView().fit(extent, {
                padding: [10, 10, 10, 10],
                duration: 300,
                ...options
            });
        };
        const setMarker = (coordinate) => {
            if (state.marker) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                source.removeFeature(state.marker);
            }
            const point = new Point(coordinate);
            state.marker = new Feature(point);
            if (state.marker) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                source.addFeature(state.marker);
            }
            fitMap(point.getExtent(), { maxZoom: 17 });
        };
        const setArea = (polygon) => {
            if (state.area) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                source.removeFeature(state.area);
            }
            state.area = new Feature(polygon);
            if (state.area) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                source.addFeature(state.area);
            }
            fitMap(polygon.getExtent(), {
                callback: () => {
                    state.areaFitted = true;
                    state.map.getView().setMinZoom(state.map.getView().getZoom() || baseMapConfig.minZoom);
                }
            });
        };
        onMounted(() => {
            state.map.setTarget(mapRef.value);
            state.map.on('click', evt => {
                state.map.forEachFeatureAtPixel(evt.pixel, () => {
                    setMarker(evt.coordinate);
                    state.position = reverse(toLonLat(evt.coordinate));
                });
            });
            watch(() => props.area, area => {
                if (area) {
                    const geom = geojson.readGeometry(area);
                    if (geom instanceof Polygon) {
                        setArea(geom);
                    }
                }
                else {
                    fitMap(extent);
                }
            }, { immediate: true });
            watch(() => ({
                areaFitted: state.areaFitted,
                value: props.value
            }), ({ areaFitted, value }) => {
                if (areaFitted && value) {
                    setMarker(fromLonLat(reverse(value), 'EPSG:3857'));
                }
            }, { immediate: true });
        });
        return {
            state,
            mapRef
        };
    }
});
