import { ref, defineComponent, reactive, watch } from '@vue/composition-api';
import { range } from '@/lib/misc';
import { imageCaptureConfig } from '@/config';
export default defineComponent({
    setup(props, { emit }) {
        const frameRef = ref();
        const videoRef = ref();
        const srcObject = ref();
        const state = reactive({
            error: null,
            captureIndex: null,
            previewingCapture: false,
            cameraActivated: false,
            captures: [],
            videoSize: {
                width: imageCaptureConfig.mediaTrackSettings.width,
                height: imageCaptureConfig.mediaTrackSettings.height
            }
        });
        const constraints = {
            video: {
                facingMode: 'environment'
            },
            audio: false
        };
        const getCameraPermission = () => navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
            const { width, height } = stream.getVideoTracks()[0].getSettings();
            state.videoSize.width = width || imageCaptureConfig.mediaTrackSettings.width;
            state.videoSize.height = height || imageCaptureConfig.mediaTrackSettings.height;
            srcObject.value = stream;
            return true;
        })
            .catch(function (err) {
            if (err.name === 'NotAllowedError') {
                throw Error('Este necesara permisiunea de a folosi camera dispozitivului');
            }
            else if (err.name === 'NotFoundError') {
                throw Error('Dispozitivul nu dispune de o camera video');
            }
            else {
                throw Error(err.message);
            }
        })
            .catch(e => {
            state.error = e.message;
            emit('error', e.message);
            return false;
        });
        const openCamera = () => getCameraPermission()
            .then(accepted => {
            state.cameraActivated = accepted;
        });
        const closeCamera = () => {
            state.cameraActivated = false;
            if (srcObject.value) {
                srcObject.value.getTracks().forEach(function (track) {
                    track.stop();
                });
            }
            if (frameRef.value) {
                const context = frameRef.value.getContext('2d');
                if (context) {
                    context.clearRect(0, 0, frameRef.value.width, frameRef.value.height);
                }
            }
        };
        const captureFrame = () => {
            if (frameRef.value && videoRef.value) {
                const context = frameRef.value.getContext('2d');
                if (context) {
                    context.drawImage(videoRef.value, 0, 0);
                    frameRef.value.toBlob((blob) => {
                        if (blob) {
                            return state.captures.push({
                                image: URL.createObjectURL(blob),
                                data: blob
                            });
                        }
                    });
                    closeCamera();
                }
            }
        };
        const previewFrame = (index) => {
            state.captureIndex = index;
            state.previewingCapture = true;
        };
        const deleteFrame = (index) => {
            if (state.captures[index].image) {
                URL.revokeObjectURL(state.captures[index].image);
                state.captures.splice(index, 1);
            }
        };
        watch(() => state.captures, () => emit('input', state.captures));
        return {
            imageCaptureConfig,
            state,
            srcObject,
            frameRef,
            videoRef,
            captureFrame,
            openCamera,
            closeCamera,
            range,
            previewFrame,
            deleteFrame
        };
    }
});
