import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { injectStrict } from '@/lib/misc';
import { AxiosKey } from '@/symbols';
export default defineComponent({
    props: {
        errors: {
            type: Object
        },
        countyId: {
            type: String
        },
        localityId: {
            type: String
        }
    },
    setup(props, { emit }) {
        const axios = injectStrict(AxiosKey);
        const fields = reactive({
            county: {
                label: 'Judet',
                value: props.countyId || null,
                errors: props.errors?.county || []
            },
            locality: {
                label: 'Localitate',
                value: props.localityId || null,
                errors: props.errors?.locality || []
            }
        });
        const areas = reactive({
            loading: false,
            error: null,
            counties: [],
            localities: computed(() => areas.counties.find((county) => county._id === fields.county.value)?.localities || [])
        });
        const loadAreas = () => {
            areas.loading = true;
            return axios.get('areas/counties')
                .then((response) => {
                areas.counties = response.data.data;
            })
                .catch(err => {
                fields.county.errors = [err.message];
                fields.locality.errors = [];
                emit('error', err.message);
            })
                .finally(() => {
                areas.loading = false;
            });
        };
        watch(() => props.countyId, v => {
            fields.county.value = v || null;
            fields.locality.value = null;
        });
        watch(() => props.localityId, v => {
            fields.locality.value = v || null;
        });
        watch(() => ({
            countyId: fields.county.value,
            localityId: fields.locality.value
        }), ({ countyId, localityId }) => {
            const county = areas.counties.find(county => county._id === countyId);
            if (!county) {
                return emit('input', null);
            }
            const locality = county.localities.find(locality => locality._id === localityId);
            if (!locality) {
                return emit('input', null);
            }
            emit('input', {
                county,
                locality
            });
        });
        watch(() => props.errors, () => {
            fields.county.errors = props.errors?.county || [];
            fields.locality.errors = props.errors?.locality || [];
        }, { immediate: true });
        loadAreas();
        return {
            fields,
            areas
        };
    }
});
