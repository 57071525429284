import { pick } from 'rambda';
import axios from 'axios';
export const formatAddress = (rawAddress) => Object
    .values(pick(['county', 'city', 'road', 'house_number', 'postcode'], rawAddress))
    .join(', ');
export const reverseLatLng = async (latLng) => axios
    .get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latLng[0]}&lon=${latLng[1]}`)
    .then(response => formatAddress(response.data.address));
const createSearchParams = (params) => Object
    .keys(params)
    .filter((key) => params[key])
    .map((key) => [key, params[key]].join('='))
    .join('&');
export const reverseAddress = ({ county, city, street }) => Promise
    .resolve({ county, city, street, countrycodes: 'ro' })
    .then(createSearchParams)
    .then((params) => axios
    .get(`https://nominatim.openstreetmap.org/search?format=jsonv2&${params}`)
    .then(response => 
// eslint-disable-next-line camelcase
response.data.map(({ place_id, display_name, lat, lon }) => ({
    displayName: display_name,
    placeId: place_id,
    latLng: {
        lat: parseFloat(lat),
        lng: parseFloat(lon)
    }
}))));
export const getCurrentPosition = () => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    });
});
export const getGeolocationErrorMessage = (code) => {
    if (code === 1)
        return 'Nu ati permis activarea optiunii de localizare a dispozitivului. Va rugam sa alegeti localitatea manual.';
    if (code === 2)
        return 'Locatia nu a putut fi determinata.';
    if (code === 3)
        return 'Determinarea locatiei a durat prea mult.';
    return 'Eroare necunoscuta la determinarea locatiei.';
};
