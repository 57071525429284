var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validatorRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h2',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","title":"Alege alta localitate"},on:{"click":function($event){return _vm.$emit('change')}}},[_vm._v(" Schimba ")]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.institution.locality.name)+", jud. "+_vm._s(_vm.institution.county.name)+" ")]),_c('v-spacer')],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"vid":"latLng"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('map-field',{staticClass:"my-2",attrs:{"area":_vm.area,"errors":errors},model:{value:(_vm.state.latLng),callback:function ($$v) {_vm.$set(_vm.state, "latLng", $$v)},expression:"state.latLng"}})]}}],null,true)}),(_vm.state.latLng)?[_c('validation-provider',{attrs:{"rules":_vm.fields.categoryId.rules,"name":_vm.fields.categoryId.label,"vid":"categoryId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.fields.categoryId.label,"error-messages":errors,"items":_vm.fields.categoryId.items,"item-text":"label","item-value":"id","filled":""},model:{value:(_vm.fields.categoryId.value),callback:function ($$v) {_vm.$set(_vm.fields.categoryId, "value", $$v)},expression:"fields.categoryId.value"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.fields.description.rules,"name":_vm.fields.description.label,"vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.fields.description.label,"error-messages":errors,"filled":""},model:{value:(_vm.fields.description.value),callback:function ($$v) {_vm.$set(_vm.fields.description, "value", $$v)},expression:"fields.description.value"}})]}}],null,true)}),_c('v-alert',{attrs:{"border":"left","color":"blue","type":"info","outlined":"","dense":""}},[_vm._v(" Optional, poti atasa poze de la locul sesizarii ")]),_c('camera-gallery',{on:{"error":_vm.toast.error},model:{value:(_vm.state.images),callback:function ($$v) {_vm.$set(_vm.state, "images", $$v)},expression:"state.images"}}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","type":"submit"}},[_vm._v(" Trimite ")])],1)]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }