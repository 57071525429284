import { defineComponent, reactive, watch } from '@vue/composition-api';
import { reverseAddress } from '@/lib/location';
import debounce from 'debounce-promise';
export default defineComponent({
    props: {
        county: {
            type: String,
            default: ''
        },
        locality: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const search = reactive({
            loading: false,
            query: null,
            selected: null,
            results: []
        });
        const searchAddress = (query) => reverseAddress({
            county: props.county,
            city: props.locality,
            street: query
        })
            // todo: Guess what... some results are out of bounds so filter the fuck out of them
            .then((results) => {
            search.results = results;
        })
            .catch(e => emit('error', e.message))
            .finally(() => {
            search.loading = false;
            emit('loading', false);
        });
        const selectAddress = (placeId) => {
            search.query = null;
            search.selected = null;
            const place = search.results.find((place) => place.placeId === placeId);
            if (place) {
                emit('input', Object.values(place.latLng));
            }
            else {
                emit('error', `PlaceID ${placeId} not found within search results.`);
            }
        };
        const debouncedSearchAddress = debounce(searchAddress, 1000, { leading: true });
        watch(() => search.query, (query) => {
            if (query) {
                search.loading = true;
                emit('loading', true);
                debouncedSearchAddress(query);
            }
        });
        return {
            search,
            selectAddress
        };
    }
});
